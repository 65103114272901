
<template>
    <div>
        <div class="box">
            <div class="box-header">
                <h3 class="box-title">Faktury</h3>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="input-group input-group-sm" style="width: 100%;margin-top: 25px">
                            <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <label>Vystavené od</label>
                        <v-date-picker mode="dateTime" v-model="fromdate" class="flex-grow" is24hr :max-date="todate ? todate : Date.now()">
                            <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="fromdate" class="form-control" autocomplete="off">
                        </v-date-picker>
                    </div>
                    <div class="col-lg-2">
                        <label>Vystavené do</label>
                        <v-date-picker mode="dateTime" v-model="todate" class="flex-grow" is24hr :min-date="fromdate" :max-date="Date.now()">
                            <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="todate" class="form-control" autocomplete="off">
                        </v-date-picker>
                    </div>
                </div>
                <br>
                <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                    <table v-if="getIssues.length" class="table table-hover">
                        <thead>
                        <tr>
                            <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="issue in getIssues" :key="issue.invoice_id">
                                <td style="cursor: pointer" @click="generateInvoice(issue.issueid, issue.invoice_id)"><i class="fa fa-file-text" aria-hidden="true"></i></td>
                                <td>{{issue.invoice_number == 0 ? issue.visible_id : issue.invoice_number}}</td>
                                <td style="cursor: pointer" @click="makeCurrentIssue(issue.issueid)"><a>{{issue.name}}</a></td>
                                <td style="cursor: pointer" @click="makeCurrentCus(issue.customerid)"><a>{{issue.company_name}}</a></td>
                                <td>{{getTime(Date.parse(issue.invoice_issued)/1000)}}</td>
                                <td>{{getTime(Date.parse(issue.invoice_due)/1000)}}</td>
                                <td>{{issue.billing_price_dph}},- Kč</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="callout callout-success">
                        <p><i class="icon fa fa-info"></i> Pro zadané parametry nebyly nalezeny žádné výsledky.</p>
                    </div>
                </div>
            </div>
            <div v-if="!loaded" class="overlay">
                <i class="fa fa-refresh fa-spin"></i>
            </div>
        </div>
        <div style="display: none">
            <section id="pdf-container" ref="pdf" style="margin-top: 1rem">
                <div class="row" style="margin-bottom: 1rem">
                    <div class="col-xs-12">
                        <div style="display: flex; align-items: end">
                            <div style="width: 50%; display: flex; border: 1px solid black; justify-content: space-between; align-items: center; padding: 1rem">
                                <span><b style="font-size: 2rem">FAKTURA</b>{{this.invoiceInfo.taxed ? " - Daňový doklad" : " - Nedaňový doklad"}}</span><span>č. {{currentIssue.invoice_number == 0 ? currentIssue.visible_id : currentIssue.invoice_number}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6" style="padding-right: 0">
                        <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                            <p><b>ZHOTOVITEL:</b></p>
                            <ul style="list-style-type: none; margin: 0">
                                <li>{{this.invoiceInfo.company_name}}</li>
                                <li>{{this.invoiceInfo.street}}</li>
                                <li>{{this.invoiceInfo.city}} {{this.invoiceInfo.zip}}</li>
                                <li><br/></li>
                                <li>Bankovní účet: {{this.invoiceInfo.bank_account}}</li>
                                <li>IČO: {{this.invoiceInfo.ico}}</li>
                                <li v-if="invoiceInfo.taxed">DIČ: {{this.invoiceInfo.dic}}</li>
                            </ul>
                            <span>{{this.invoiceInfo.business_register}}</span>
                        </div>
                    </div>
                    <div class="col-xs-6" style="padding-left: 1rem">
                        <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                            <p><b>ODBĚRATEL:</b></p>
                            <ul style="list-style-type: none; margin: 0">
                                <li>{{currentIssue.company_name}}</li>
                                <li>{{currentIssue.invoice_street}}</li>
                                <li>{{currentIssue.invoice_city}} {{currentIssue.invoice_zip}}</li>
                                <li><br/></li>
                                <li><br/></li>
                                <li>IČO: {{this.currentIssue.ico}}</li>
                                <li>DIČ: {{this.currentIssue.dic}}</li>
                            </ul>
                            <span><br/></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6" style="padding-right: 0">
                        <div style="border: 1px solid black; padding: 1rem">
                            <p><b>VOZIDLO:</b></p>
                            <ul style="list-style-type: none; margin: 0">
                                <li><b>Jméno: </b>{{currentIssue.car_name}}</li>
                                <li><b>VIN: </b>{{currentIssue.vin_code}}</li>
                                <li><b>SPZ: </b>{{currentIssue.licence_plate}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-6" style="padding-left: 1rem">
                        <div style="border: 1px solid black; margin-bottom: 1rem; padding: 1rem">
                            <p><b>FAKTURAČNÍ ÚDAJE:</b></p>
                            <ul style="list-style-type: none; margin: 0">
                                <li><b>Forma úhrady: </b>{{paymentType}}</li>
                                <li><b>Datum vystavení: </b>{{currentInvoice.created}}</li>
                                <li><b>Datum splatnosti: </b>{{currentInvoice.due_date}}</li>
                                <li v-if="invoiceInfo.taxed"><b>Datum UZP: </b>{{currentInvoice.uzp_date}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12" :style="{marginBottom: invoiceInfo.taxed ? '1rem' : ''}">
                        <div style="border: 1px solid black">
                            <div style="padding: 1rem; padding-bottom: 0">
                                <p><b>ROZSAH A PŘEDMĚT ZDANITELNÉHO PLNĚNÍ:</b></p>
                            </div>
                            <table class="table table-condensed" style="margin-bottom: 0">
                                <thead>
                                <tr>
                                    <th>Popis</th>
                                    <th>Typ</th>
                                    <th>Prodejní cena/Hodinová cena</th>
                                    <th v-if="invoiceInfo.taxed">DPH</th>
                                    <th>Fakturovaný čas/Množství</th>
                                    <th v-if="!invoiceInfo.taxed"></th>
                                    <th>{{invoiceInfo.taxed ? "Celkem s DPH" : "Celkem"}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="operation in this.ops" v-bind:key="operation.order">
                                        <td>{{operation.optitle}}</td>
                                        <td>{{operation.type == 0 ? "Práce" : "Materiál"}}</td>
                                        <td>{{operation.invoice_price}}</td>
                                        <td v-if="invoiceInfo.taxed" style="white-space: nowrap">{{operation.dph}} %</td>
                                        <td>{{operation.amount}}</td>
                                        <td v-if="!invoiceInfo.taxed"></td>
                                        <td>{{Math.round(((operation.amount * operation.invoice_price * ((operation.dph / 100) + 1)) + Number.EPSILON) * 100) / 100}}</td>
                                    </tr>
                                    <tr v-if="this.currentInvoice.payment_type === 1">
                                        <td style="border-top: 1px solid black">Zaokrouhleno</td>
                                        <td style="border-top: 1px solid black"></td>
                                        <td style="border-top: 1px solid black"></td>
                                        <td style="border-top: 1px solid black"></td>
                                        <td style="border-top: 1px solid black"></td>
                                        <td style="border-top: 1px solid black">{{this.addMoney}}</td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td>CELKEM K ÚHRADĚ</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{{this.formatNumber(this.currentIssue.billing_price_dph + this.addMoney)}} Kč</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row avoid-pdf-break" v-if="invoiceInfo.taxed">
                    <div class="col-xs-12">
                        <div style="border: 1px solid black">
                            <div style="padding: 1rem; padding-bottom: 0">
                                <p><b>REKAPITULACE DPH (Kč):</b></p>
                            </div>
                            <table class="table table-condensed" style="margin-bottom: 0; margin-left: 5px; width: 50%">
                                <thead>
                                    <tr>
                                        <th>Sazba</th>
                                        <th>Daňový základ</th>
                                        <th>DPH</th>
                                        <th>Celkem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Základní (21 %)</td>
                                        <td>{{this.dph21base}}</td>
                                        <td>{{this.dph21}}</td>
                                        <td>{{Math.round((this.dph21 + this.dph21base + Number.EPSILON) * 100) / 100}}</td>
                                    </tr>
                                    <tr>
                                        <td>Snížená (12 %)</td>
                                        <td>{{this.dph12base}}</td>
                                        <td>{{this.dph12}}</td>
                                        <td>{{Math.round((this.dph12 + this.dph12base + Number.EPSILON) * 100) / 100}}</td>
                                    </tr>
                                    <tr v-if="this.currentInvoice.payment_type === 1">
                                        <td>Osvobozeno</td>
                                        <td>{{this.addMoney}}</td>
                                        <td>0</td>
                                        <td>{{this.addMoney}}</td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td>Celkem</td>
                                        <td>{{Math.round((this.dph21base + this.dph12base + this.addMoney) * 100) / 100}}</td>
                                        <td>{{this.dph21 + this.dph12}}</td>
                                        <td>{{this.formatNumber(this.currentIssue.billing_price_dph + this.addMoney)}} Kč</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <small v-if="!this.invoiceInfo.taxed">Servis není plátcem DPH</small>
                <p style="text-align: center"><small>Faktura byla vygenerována systémem ANASAP</small></p>
            </section>
        </div>
    </div>
</template>

<script>

const name = 'Invoices';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        const threshold = parseInt(this.$route.query.threshold) || 30
        const headColumns = [
            { name: 'Zobrazit' },
            { name: 'Číslo faktury' },
            { name: 'Úkon' },
            { name: 'Zákazník' },
            { name: 'Datum vystavení' },
            { name: 'Datum splatnosti' },
            { name: 'Částka s DPH' },
        ]

        this.headColumnsInvoice = [
            { name: 'Popis' },
            { name: 'Typ' },
            { name: 'Prodejní cena/Hodinová cena' },
            { name: 'DPH' },
            { name: 'Fakturovaný čas/Množství' },
            { name: 'Celkem s DPH' },
        ]

        var d = new Date();
        d.setHours(23,59,0,0);
        var todate = d.getTime();

        d = new Date();
        d.setDate(d.getDate()-threshold);
        d.setHours(0,0,0,0);
        var fromdate = d.getTime();

        return {
            filter: '',
            loaded: false,
            threshold,
            headColumns,
            fromdate,
            todate,
            paymentType: null,
            dph21base: 0,
            dph21: 0,
            dph12base: 0,
            dph12: 0,
            addDays: 0,
            addMoney: 0,
            ops: []
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'allIssues', 'user', 'group', 'currentInvoice', 'currentIssue', 'invoiceInfo']),
        getIssues() {

            var issues = this.allIssues;

            if(!issues) return [];

            return issues.filter( (issue) => {

                return (issue.closed > 0 && issue.invoice_id && Date.parse(issue.invoice_issued) >= this.fromdate && Date.parse(issue.invoice_issued) <= this.todate
                && (this.$helpers.filterCheck(this.filter, issue.name)
                        || this.$helpers.filterCheck(this.filter, issue.company_name)
                        || this.$helpers.filterCheck(this.filter, issue.invoice_number != 0 ? String(issue.invoice_number) : String(issue.visible_id))
                        ));

            });
        }
    },
    methods: {
        ...mapActions(["fetchInvoiceInfo", "makeActiveTab", "addTab", "fetchIssues", 'fetchIssue', 'fetchCustomer', 'makeCurrentIssue', 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt', 'fetchUser', 'fetchMaintainer', 'fetchInvoice']),
        getTime(epochTime) {
            const dt = new Date(epochTime * 1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();

            return `${day}.${mth}.${yrs}`;
        },
        formatNumber(x)
        {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        generateInvoice(issue_id, invoice_id) {
            this.fetchIssue(issue_id).then(() => {

                this.ops = this.currentIssue.ops;
                for (let i = 0; i < this.currentIssue.ops.length; i++) {
                    this.ops[i].description = this.currentIssue.ops[i].optitle
                }
                this.fetchCustomer(this.currentIssue.customerid);

                this.fetchInvoice(invoice_id).then(() => {
                    if (this.currentInvoice.payment_type === 1)
                    {
                        this.paymentType = "Hotově"
                        this.addDays = 0
                        this.addMoney = Math.round(((Math.round(this.currentIssue.billing_price_dph) - this.currentIssue.billing_price_dph) + Number.EPSILON) * 100) / 100
                    }
                    else if (this.currentInvoice.payment_type === 2)
                    {
                        this.paymentType = "Kartou"
                        this.addDays = 0
                    }
                    else if (this.currentInvoice.payment_type === 3)
                    {
                        this.paymentType = "Převod na účet"
                        this.addDays = 14
                    }

                    this.calculatePrice();
                    this.generateReport();
                });

            });
        },
        generateReport () {
            var opt = {
                margin:       0.5,
                filename:     'Faktura.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 2, scrollX: 0, scrollY: 0 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak:    { avoid: '.avoid-pdf-break'}
            };

            window.html2pdf().from(this.$refs.pdf).set(opt).toPdf().get('pdf').then(function (pdf) {
                window.open(pdf.output('bloburl'), '_blank');
            });
        },
        calculatePrice()
        {
            this.ops.forEach((op) => {

                if (op.dph === 21)
                {
                    this.dph21base += (op.invoice_price * op.amount)
                }
                else if (op.dph === 12)
                {
                    this.dph12base += (op.invoice_price * op.amount)
                }
            })

            this.dph21 = Math.round(((this.dph21base * 0.21) + Number.EPSILON) * 100) / 100
            this.dph12 = Math.round(((this.dph12base * 0.12) + Number.EPSILON) * 100) / 100

            this.dph21base = Math.round((this.dph21base + Number.EPSILON) * 100) / 100
            this.dph12base = Math.round((this.dph12base + Number.EPSILON) * 100) / 100
        },
    },
    created() {
        const page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(page);
        this.makeActiveTab(page);

        this.fetchInvoiceInfo();

        this.fetchIssues().then(() => {
            this.loaded = true;
        });
    }
}
</script>
